

body {
  background-color: honeydew;
}

footer {
  font-style: normal;
  font-size: 12px;
}

.centered {
  text-align: center;
}

/*  center the Collection component on the screen */
.collectiondiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-image {
  cursor: pointer !important;
}

/* react-burger-menu specific styles */
* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

.burger-margins {
  margin-right: 80px;
  margin-bottom: 0px;
}

/* end of react-burger-menu styles */


